<template>
  <div>
    <div class="create-company"></div>

    <el-tabs
      @tab-click="handleClick"
      type="border-card"
      v-model="activeName"
      style="margin-top: 10px"
    >
      <el-tab-pane label="Information" name="first">
        <div class="plan_info">
          <div class="sub_tab_inn">
            <h3>Subscription Details</h3>
            <div class="employee-modal text-left">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="">Employees Registered</label>
                    <div class="bold">{{ RegisteredEmployee }}</div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="">Number of companies registered</label>
                    <div class="bold">{{ NumberOfRegisteredCompanies }}</div>
                  </div>
                </div>
              </div>
            </div>
            <h3>Subscription information</h3>
            <p>$6.00 AUD for 1 to 5 employees.</p>
            <p>$10.00 AUD for 6 to 10 employees.</p>
            <p>$15.00 AUD over 10 employees.</p>
            <p v-show="IsAccountant">$10.00 AUD for each additional companies</p>
            <p v-show="!IsAccountant">$15.00 AUD for each additional companies</p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Card Details" name="second">
        <div class="sub_tab_inn">
          <div class="card_payment">
            <!-- Nav tabs -->
            <!-- <ul class="nav nav-tabs" role="tablist"> -->
              <!-- <li role="presentation" class="active"> -->
                <!-- <a
                  href="#CardTab1"
                  aria-controls="Cardt1"
                  role="tab"
                  data-toggle="tab"
                  >
                  <img src="/assets/img/payment_logo_1.png" alt=""
                />
              </a> -->
              <!-- </li> -->
              <!-- <li role="presentation">
                <a
                  href="#CardTab2"
                  aria-controls="Cardt2"
                  role="tab"
                  data-toggle="tab"
                  ><img src="/assets/img/payment_logo_2.png" alt=""
                /></a>
              </li> -->
            <!-- </ul> -->
            <!-- Tab panes -->
            <div class="tab-content">
              <div
                name="stripe"
                role="tabpanel"
                class="tab-pane active"
                id="CardTab1"
              >
                <h3>New Card Details</h3>
                <div class="pay_forms">
                  <StripeCard
                    @card-saved="getCurrentSubscription"
                    :Plan="PlanToBeUpgraded"
                  />
                </div>
              </div>
              <!-- <div
                name="cordless"
                role="tabpanel"
                class="tab-pane"
                id="CardTab2"
              >
                <h3>Set up a Direct Debit</h3>

                <GoCardless
                  @GetCurrentSubscription="getCurrentSubscription"
                  :Plan="PlanToBeUpgraded"
                />
              </div> -->
            </div>
          </div>
            <div v-if="SetOldCurrentSubscription.length > 0">
              <h3>Current cards on file</h3>
              <div 
                v-for="card in SetOldCurrentSubscription" 
                :key="card.CardId"
                :class="{ 'selected-card': selectedCards.includes(card.cardId) }"
                class="card-container"
                @click="toggleCardSelection(card.cardId)"
              >
                <p>Card: <strong>**** **** **** {{ card.last4 }}</strong></p>
                <p>Brand: <strong>{{ card.brand }}</strong></p>
                <p>Expired: <strong>{{ card.expMonth }}/{{ card.expYear }}</strong></p>
              </div>
              <button 
              v-if="selectedCards.length > 0"
              class="w-full bg-red-500 text-white btn btn-danger py-2 rounded-lg text-sm font-semibold mt-2 hover:bg-red-600 transition-all"
               @click="deleteSelectedCards">Delete selected card</button>
            </div>
          </div>
      </el-tab-pane>
      <!--  -->
      <el-tab-pane label="Subscriptions" name="third">
        <Invoices ref="invoiceComponent" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import * as Session from "@/utils/SessionStore";
import moment from "moment";
import * as Utils from "@/utils/Utils";

import {
  GET_ACTIVE_PLANS,
  CREATE_STRIPE_CUSTOMER,
  DEACTIVATE_SUBSCRIPTION,
  List_STRIPE_CUSTOMER,
  DELETE_STRIPE_CUSTOMER,
} from "@/store/actionsType";

import StripeCard from "./components/StripeCard.vue";
// import GoCardless from "./components/GoCardless.vue";
import Invoices from "./components/Invoices.vue";


export default {
  name: "Settings",
  props: [],
  components: {
    StripeCard,
    // GoCardless,
    Invoices,
  },
  data() {
    return {
      isCheckAll: false,
      SetOldCurrentSubscription: [],
      selectedCards: [],
      SelectedPlann: [],
      activeName: "first",
      ListPlans: [],
      CurrentPlanId: 0,
      PlanToBeUpgraded: 0,
      CurrentSubscription: {},
      RegisteredEmployee: 0,
      NumberOfRegisteredCompanies: 0,
    };
  },
  created: function () {
    this.$root.$refs.subscription = this;
    this.getCurrentSubscription();
  },
  computed: {
    IsAccountant() {
      return this.getProfile().role === 'Accountant';
    },
  },
  methods: {
    getProfile() {
      return Session.get("Profile");
    },
    handleClick(tab) {
      if (tab.label == "Card Details") {
        if (this.SelectedPlann.length > 0) {
          this.PlanToBeUpgraded = this.SelectedPlann[0];
          this.UpgradeSubscription();
        }
        this.GetListSubsription();
      }
    },
    toggleCardSelection(cardId) {
      const index = this.selectedCards.indexOf(cardId);
      if (index === -1) {
        this.selectedCards.push(cardId);
      } else {
        this.selectedCards.splice(index, 1);
      }
    },
    
    CheckTrialPlan(plan) {
      if (plan.title) {
        var st = plan.title.toLowerCase();
        if (st.indexOf("trial") >= 0) {
          if (plan.id == this.CurrentPlanId) {
            return false;
          } else {
            return true;
          }
        } else return false;
      } else return false;
    },
    getListPlans() {
      if (Session.has("currenCompanyId")) {
        //   this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(GET_ACTIVE_PLANS)
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            this.ListPlans = response.reverse().sort((a, b) => a.id - b.id);

            // this.ListPlans = response.reverse();
          })
          .catch(() => {
            
          });
      }
    },
    getCurrentSubscription() {

      const profile = this.getProfile();
      this.RegisteredEmployee = profile.currentEmpCount;
      this.NumberOfRegisteredCompanies = profile.currentCompanyCount;
      this.GetListSubsription();
    },
    ClickPay() {
      this.PlanToBeUpgraded = this.SelectedPlann[0];
      this.MoveToOtherTab();
      this.UpgradeSubscription();
    },
    MoveToOtherTab() {
      this.activeName = "second";
    },
    updateCheckall: function (planid, event) {
      this.SelectedPlann = [];
      this.SelectedPlann.push(planid);
      if (event.target.checked == false) {
        this.SelectedPlann = [];
        this.PlanToBeUpgraded = 0;
      }
    },
    UpgradeSubscription() {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(CREATE_STRIPE_CUSTOMER)
          .then(() => {
            this.$root.$refs.AppCyrus.finish();
            // this.ListPlans = response.reverse();
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
    GetListSubsription() {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(List_STRIPE_CUSTOMER)
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            this.SetOldCurrentSubscription = response
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
    deleteSelectedCardsService() {
      this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(DELETE_STRIPE_CUSTOMER ,{ cardIds: this.selectedCards })
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            this.GetListSubsription();
            this.selectedCards = [];
            if (response.status == "succeeded" || response.status == "active") {
              Utils.toastSuccess("Card delete succeeded!");
            } else {
              Utils.toastError(response.status);
            }
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
    },
    formatDate(inputDate, dateFormat) {
      var date = moment(inputDate);
      dateFormat = dateFormat || "DD/MM/YYYY";
      if (date.isValid()) {
        return moment(inputDate).format(dateFormat);
      } else {
        return "-";
      }
    },
    deleteSelectedCards() {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete your card? You will not be able to recover this card.",
        type: "message",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteSelectedCardsService();
        }
      });
    },
    DeactivateSubscription() {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure want to de-activate your subscription? you will have no more access to this platform.",
        type: "message",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          //this.DeactivateSubscriptionService();
        }
      });
    },
    DeactivateSubscriptionService() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(DEACTIVATE_SUBSCRIPTION)
        .then(() => {
          var Swal = this.$swal;
          Swal.fire({
            title: "Auto log-out alert!",
            html: "Subscription deactivated. You will be logged-out in 5 seconds.",
            timer: 5000,
            timerProgressBar: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              setInterval(() => {
                var ss = parseInt(Swal.getTimerLeft() / 1000);
                b.textContent = ss;
              }, 100);
            },
            willClose: () => {
              // clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$root.$refs.BaseLayout.requestLogout();
            }
          });

          this.$root.$refs.AppCyrus.finish();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
  },
};
</script>
<!-- <style scoped>
// 
// </style>-->
<style scoped>
@import "/assets/css/subscription.css";
.Custom-Error{
  color:crimson
}
.hide-popup {
  display: none;
}
.btn-upg {
  cursor: pointer;
}
.disabled {
  cursor: no-drop;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(9, 181, 133) !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(9, 181, 133) !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.card-container {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border 0.1s ease, background-color 0.1s ease;
  cursor: pointer;
  margin-bottom: 10px;
}

.selected-card {
  border: 1px solid #007bff; /* Màu xanh khi chọn */
  background-color: #f0f8ff;
  box-shadow: 0px 0px 10px #007bff;
}
</style>
